import Button from "@/components/common/atom/Button/Button"
import FormField from "@/components/common/atom/FormField/FormField"
import TextField from "@/components/common/atom/TextField/TextField"
import { yupResolver } from "@hookform/resolvers/yup"
import { Col, ConfigProvider, Row, Space } from "antd"
import { useEffect, useMemo } from "react"
import { Controller, FieldError, useForm } from "react-hook-form"
import { Size } from "@/enums/common.enum"
import BackToLogin from "../BackToLogin/BackToLogin"
import { RESET_PASSWORD_SCHEMA } from "@/validations/resetPassword.validation"
import {
  NewPasswordDto,
  useCreateNewPasswordMutation
} from "@/services/api/auth"
import { errorStatus } from "@/constants/common.constant"
import { useLocation, useNavigate } from "react-router-dom"
import { getQueryParams } from "@/utils/stringHelper"
import { useLoading } from "@/hooks/useLoading"
import { useResetPasswordMutation } from "@/services/apiDigifabster/auth"
import * as toast from "@/utils/Toast"

interface IResetPasswordProps {}

export interface IResetPasswordForm {
  newPassword: string
  confirmPassword: string
}

const ResetPWForm: React.FC<IResetPasswordProps> = ({}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(RESET_PASSWORD_SCHEMA),
    mode: "onSubmit"
  })
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [handleResetPassword, { isLoading, isSuccess, error }] =
    useResetPasswordMutation()
  const { openLoading, closeLoading } = useLoading()

  useEffect(() => {
    if (!isSuccess) return

    navigate("/auth/forgot-password/success")
  }, [isSuccess])

  useEffect(() => {
    if (isLoading) {
      openLoading()
    } else {
      closeLoading()
    }
  }, [isLoading])

  useEffect(() => {
    if (!error) return
    toast.showError("Error", "Something went wrong. Please try again.")
  }, [error])

  const onSubmit = (data: IResetPasswordForm) => {
    const params = getQueryParams(decodeURIComponent(pathname))
    const body: NewPasswordDto = {
      newPassword: data.newPassword,
      token: params.token,
      email: params.email
    }
    handleResetPassword(body)
  }

  return (
    <form onSubmitCapture={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: 20 }}>
        <FormField
          textLabel="New password"
          errorText={errors.newPassword?.message}
          size="medium"
        >
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                autoComplete={"new-password"}
                status={errorStatus(errors.newPassword)}
                placeholder="Enter your new password"
                {...field}
              />
            )}
          ></Controller>
        </FormField>
      </div>
      <div style={{ marginBottom: 40 }}>
        <FormField
          textLabel="Confirm new password"
          errorText={errors.confirmPassword?.message}
          size="medium"
        >
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                autoComplete="new-password"
                status={errorStatus(errors.confirmPassword)}
                placeholder="Confirm new password"
                {...field}
              />
            )}
          ></Controller>
        </FormField>
      </div>
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col xs={24} md={16} lg={12}>
          <ConfigProvider
            theme={{
              token: {
                paddingLG: 12
              }
            }}
          >
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ padding: "12px 24px", width: "100%" }}
                customSize={Size.EXTRA_LARGE}
              >
                Set new password
              </Button>
              <BackToLogin />
            </Space>
          </ConfigProvider>
        </Col>
      </Row>
    </form>
  )
}

export default ResetPWForm
