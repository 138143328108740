import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"

export default function NavigatePage(): JSX.Element {
  const { search } = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(search)
  const invoiceUrl = queryParams.get("invoice_url")
  useEffect(() => {
    if (invoiceUrl) {
      const parts = invoiceUrl.split("/")
      const invoiceId = parts[parts.length - 2]
      const invoiceHash = parts[parts.length - 1]

      navigate(`/quotes/invoices/${invoiceId}/${invoiceHash}`, {
        replace: true
      })
    } else {
      navigate("/not-found", { replace: true })
    }
  }, [invoiceUrl])

  return <div />
}
