export enum ESHIPPING_METHOD {
  FEDEX = "FedEx",
  UPS = "UPS",
  PUROLATOR = "Purolator"
}

export enum EDeliveryCarrier {
  FEDEX = "fedex",
  UPS = "ups",
  PUROLATOR = "purolator"
}

export enum EDeliveryTime {
  FEDEX = "Standard Overnight",
  UPS = "Next Day",
  PUROLATOR = "Next Day"
}
