import React, { useMemo } from "react"
import "./OrderSummary.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { Loading, Button } from "@/components"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"
import { EPaymentMethod } from "@/enums/checkout.enum"
import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import {
  billingAddressSectionIcon,
  DeliveryAddressIcon,
  shippingMethodIcon,
  WarningDFMIcon
} from "@/assets"
import { EShippingMethod } from "@/enums/checkout.enum"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { TIME_WITH_METHOD } from "@/constants/method-shipping.constant"
import { useNavigate } from "react-router-dom"
import { IBillingFormValues } from "@/interfaces/billing.interface"
import { useFormContext } from "react-hook-form"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { EModelAnalysisStatus } from "@/store/product"
interface IOrderSummaryProps {
  isLoading?: boolean
  orderData: ICheckoutForm
  paymentMethod: string
  onSubmit: (data?: IPaymentForm) => void
  handleBack: () => void
  disabled: boolean
  isRequiresReview: boolean
  submitText: string
}
const OrderSummary: React.FC<IOrderSummaryProps> = ({
  isLoading = false,
  paymentMethod,
  orderData,
  onSubmit,
  disabled,
  handleBack,
  isRequiresReview,
  submitText
}: IOrderSummaryProps) => {
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { currency } = useCurrency()
  const { settings } = useSelector((state: RootState) => state.user)
  const minOrderValue = settings?.minimum_order_amount || 0
  const navigate = useNavigate()
  const { handleSubmit, watch } = useFormContext<IBillingFormValues>()

  if (!quoteDetail || !quoteDetail.line_items) {
    return <Loading></Loading>
  }
  const onSubmitDataBilling = (data: IBillingFormValues) => {
    const mappedData: IPaymentForm = {
      billing: { ...data, poNumber: watch("poNumber") },
      poFile: watch("poFile")[0]
    }
    onSubmit(mappedData)
  }

  const {
    delivery_price,
    total_price,
    tax_value,
    startup_cost,
    total_discount_value,
    models_naked_price,
    price_corrections_cost,
    status
  } = quoteDetail

  const sections = useMemo(() => {
    return [
      {
        title: "Delivery Address",
        condition: orderData?.delivery_address,
        text: `${orderData?.delivery_address?.city}, ${orderData?.delivery_address?.zipCode}`,
        icon: DeliveryAddressIcon
      },
      {
        title: "Shipping Method",
        condition: orderData?.shipping_method,
        text: orderData?.shipping_carrier
          ? `${orderData?.shipping_carrier} ${
              TIME_WITH_METHOD[
                orderData?.shipping_carrier as keyof typeof TIME_WITH_METHOD
              ]
            }`
          : orderData?.shipping_method === EShippingMethod.LOCAL_PICKUP
          ? "Pick Up"
          : "",
        icon: shippingMethodIcon
      },
      {
        title: "Billing Address",
        condition:
          orderData?.billing && paymentMethod !== EPaymentMethod.CREDIT_CARD,
        text: `${orderData?.billing?.city}, ${orderData?.billing?.zipCode}`,
        icon: billingAddressSectionIcon
      }
    ]
  }, [orderData])

  const transferText = (text: string): string => {
    const formattedTextMap: Record<string, string> = {
      [EShippingMethod.LOCAL_PICKUP]: "PICK UP",
      ups: "UPS"
    }

    return (
      formattedTextMap[text] || text.charAt(0).toUpperCase() + text.slice(1)
    )
  }

  const isCannotOrder = useMemo(() => {
    return (
      Number(models_naked_price) <= minOrderValue &&
      !isRequiresReview &&
      status !== EOrderStatus.FIRM_OFFER_SENT
    )
  }, [quoteDetail, minOrderValue])

  const waitingPrice = useMemo(() => {
    return quoteDetail?.line_items?.reduce((acc, item) => {
      if (item.status === EOrderStatus.WAITING_FOR_REVIEW) {
        return acc + item.total_price
      }
      return acc
    }, 0)
  }, [quoteDetail])

  return (
    <div className="order-summary-container">
      <div className="order-summary-title">
        <h3>Order summary</h3>
        {isCannotOrder && (
          <div className="order-summary-warning">
            <div className="order-summary-warning-title">
              <img src={WarningDFMIcon} width={24} height={24} alt="" />
              <p>Below Minimum Order</p>
            </div>
            <p className="order-summary-warning-content">
              Your order is just{" "}
              <b>
                {currency}
                {priceDisplay(Number(models_naked_price))}
              </b>{" "}
              below our minimum order. Add a few more part for the best value,
              or proceed as is.
            </p>
          </div>
        )}
      </div>
      <div className="order-summary-content">
        {(orderData?.delivery_address ||
          orderData?.shipping_method ||
          orderData?.billing) && (
          <div className="order-summary-section">
            {sections.map(
              (section, index) =>
                section.condition && (
                  <div className="order-summary-section-item" key={index}>
                    <img
                      src={section.icon}
                      height={24}
                      width={24}
                      alt={`${section.title} icon`}
                    />
                    <div className="order-summary-section-item-text">
                      <p>{section.title}</p>
                      <p>{transferText(section.text)}</p>
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        <div className="order-summary-price">
          <div className="order-summary-price-section">
            <p>Subtotal</p>
            <p>
              {currency}
              {priceDisplay(Number(models_naked_price - waitingPrice))}
            </p>
          </div>
          {startup_cost > 0 && (
            <div className="order-summary-price-section">
              <p>Minimum Order Fee</p>

              <p>
                {currency}
                {priceDisplay(startup_cost || 0)}
              </p>
            </div>
          )}

          {Number(price_corrections_cost) > 0 && (
            <div className="order-summary-price-section">
              <p>Additional cost</p>

              <p>
                {currency}
                {priceDisplay(Number(price_corrections_cost) || 0)}
              </p>
            </div>
          )}

          <div className="order-summary-price-section">
            <p>Delivery</p>
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <p>
                {currency}
                {priceDisplay(Number(delivery_price))}
              </p>
            )}
          </div>
          <div className="order-summary-price-section">
            <p>Tax</p>
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <p>
                {currency}
                {priceDisplay(tax_value || 0)}
              </p>
            )}
          </div>
          {total_discount_value > 0 && (
            <div className="order-summary-price-section">
              <p>Discounts</p>

              <p>
                {currency}
                {priceDisplay(total_discount_value || 0)}
              </p>
            </div>
          )}
        </div>

        <div className="order-summary-total-price">
          <p>Total</p>
          <p>
            {currency}
            {priceDisplay(Number(total_price - waitingPrice))}
          </p>
        </div>

        <Button
          htmlType="submit"
          customClassName="order-summary-btn"
          customSize={Size.LARGE}
          onClick={
            paymentMethod !== EPaymentMethod.CREDIT_CARD
              ? handleSubmit(onSubmitDataBilling)
              : () => onSubmit()
          }
          disabled={disabled}
        >
          {submitText}
        </Button>
      </div>
    </div>
  )
}

export default OrderSummary
