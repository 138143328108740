import { useEffect, useRef } from "react"
import AbandonedCartItem from "../AbandonedCartItem/AbandonedCartItem"
import "./AbandonedListCart.scss"
import { Quote } from "@/services/apiDigifabster/quote"
import { Loading } from "@/components"
import { useCurrency } from "@/hooks/useCurrency"

interface IAbandonedCartProps {
  data: Quote[]
  fetchMoreData: () => void
  hasMore: boolean
  isFetching: boolean
}

function AbandonedCart({
  data,
  fetchMoreData,
  hasMore,
  isFetching
}: IAbandonedCartProps) {
  const listInnerRef = useRef<HTMLDivElement>(null)
  const { currency } = useCurrency()
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= scrollHeight) {
        if (hasMore && !isFetching) {
          fetchMoreData()
        }
      }
    }
  }

  useEffect(() => {
    const currentRef = listInnerRef.current
    if (currentRef) {
      currentRef.addEventListener("scroll", onScroll)
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", onScroll)
      }
    }
  }, [data, hasMore, isFetching])

  return (
    <div className="abandon-cart-container">
      <div className="abandon-cart-banner">
        <p>
          <span>Pick Up Where you Left Off</span>
        </p>
      </div>
      <div className="abandon-cart" ref={listInnerRef}>
        {data.map((item) => (
          <AbandonedCartItem
            currency={currency}
            item={item}
            key={item.id}
          ></AbandonedCartItem>
        ))}
        {isFetching && (
          <div
            className="loading-more"
            style={{ height: !data.length ? "500px" : "" }}
          >
            <Loading></Loading>
          </div>
        )}
      </div>
    </div>
  )
}

export default AbandonedCart
