import { FedExLogo, PurolatorLogo, UpsLogo } from "@/assets"

export const mockMethod = [
  {
    img: PurolatorLogo,
    value: "purolator",
    name: "Purolator",
    time: "Next Day Express",
    price: 9.95
  },
  {
    img: UpsLogo,
    value: "ups",
    name: "UPS",
    time: "Next Day Air",
    price: 26.26
  },
  {
    img: FedExLogo,
    value: "fedex",
    name: "FedEx",
    time: "Standard Overnight",
    price: 50.11
  }
]

export const TIME_WITH_METHOD = {
  purolator: "Next Day",
  ups: "Next Day",
  fedex: "Standard Overnight"
}
