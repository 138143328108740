import { format } from "date-fns"
import "./AbandonedCartItem.scss"
import { Quote, useGetQuoteQuery } from "@/services/apiDigifabster/quote"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size
} from "@/enums/common.enum"
import { Button, CustomBadge } from "@/components"
import { useNavigate } from "react-router-dom"
import { priceDisplay } from "@/utils/functionHelper"

interface IAbandonedCartItemProps {
  item: Quote
  currency: string
}
const changeStatusOfCart = (status: string) => {
  if (status === "Initial") {
    return "Auto-Quoted"
  }
  return status
}

function AbandonedCartItem({ item, currency }: IAbandonedCartItemProps) {
  const totalPart = item?.line_items?.length || 0
  const navigate = useNavigate()
  const resumeOrder = (id: number) => {
    navigate(`specification/${id}`, { replace: true })
  }
  return (
    <div className="abandoned-cart-item-box">
      <p>Q-{item.id}</p>
      <p>
        <CustomBadge
          content={`${totalPart} ${totalPart > 1 ? "parts" : "part"}`}
          type={BadgeType.TONAL}
          color={BadgeColor.ROLE}
        ></CustomBadge>
      </p>
      <p>
        {currency}
        {priceDisplay(Number(item.total_price) - Number(item.startup_cost))}
      </p>
      <p>{format(item.date, "MM/dd/yyyy")}</p>
      <p>
        <CustomBadge
          content={changeStatusOfCart(item.status_display)}
          type={BadgeType.TONAL}
          color={BadgeColor.SUCCESS}
          size={Size.MEDIUM}
        ></CustomBadge>
      </p>
      <div>
        <Button
          hierarchy={ButtonHierarchy.OUTLINE}
          onClick={() => resumeOrder(item.id)}
        >
          Resume
        </Button>
      </div>
    </div>
  )
}

export default AbandonedCartItem
