import "./EditBillingPage.scss"
import ShippingContainer from "../../components/ShippingContainer/ShippingContainer"
import BillingForm from "../../components/BillingForm/BillingForm"
import { IBillingForm } from "@/interfaces/billing.interface"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { BILLING_SCHEMA } from "@/validations/billing.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelector } from "react-redux"
import { IUserStore } from "@/store/user"
import { RootState } from "@/store"
import {
  useLazyGetBillingAddressQuery,
  useUpdateBillingAddressMutation
} from "@/services/api/user"
import { useEffect } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { isEmptyObject } from "@/utils/dataHelper"
import { showError, showSuccess } from "@/utils/Toast"
import { DEFAULT_FORM_VALUES } from "@/constants/shipping.constants"

export default function EditBillingPage(): JSX.Element {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    reset
  } = useForm<IBillingForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(BILLING_SCHEMA)
  })

  const { billingAddress } = useSelector<RootState, IUserStore>((s) => s.user)
  const [getBillingAddress, { isLoading, isError, data, isUninitialized }] =
    useLazyGetBillingAddressQuery()
  const [
    updateBillingAddress,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess
    }
  ] = useUpdateBillingAddressMutation()
  useAppLoading([isLoading, isUpdateLoading])

  useEffect(() => {
    if (!isUninitialized && (isError || isEmptyObject(data))) {
      navigate("/profile/billing-address")
      return
    }

    if (!data) return
  }, [isError, data])

  useEffect(() => {
    if (isUpdateError) {
      showError("Update billing address error")
    }

    if (isUpdateSuccess) {
      getBillingAddress()
      showSuccess("Update billing address success")
      navigate("/profile/billing-address")
    }
  }, [isUpdateError, isUpdateSuccess])

  useEffect(() => {
    if (!billingAddress) {
      getBillingAddress()
    } else {
      reset(billingAddress)
    }
  }, [billingAddress])

  const navigate = useNavigate()

  const onSubmit = (data: IBillingForm) => {
    updateBillingAddress({ addUpdateBillingAddressDto: data })
    // navigate("/profile/billing-address")
  }

  const onCancel = () => {
    navigate("/profile/billing-address")
  }
  return (
    <ShippingContainer>
      <div className="billing-content">
        <div className="billing-address-header">
          <h3>Edit Billing Address</h3>
        </div>
        <div className="">
          <BillingForm
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            watch={watch}
            setValue={setValue}
            onCancel={onCancel}
          />
        </div>
      </div>
    </ShippingContainer>
  )
}
