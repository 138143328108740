import {
  BlackColor,
  DMLSIcon,
  FDMIcon,
  Logo,
  MJFIcon,
  SLAIcon,
  SLSIcon,
  StandardFinish,
  VapourSmoothing,
  WhiteColor
} from "@/assets"
import {
  EColor,
  ELayerHeight,
  EManufacturingProcess,
  EMaterial,
  ESurfaceFinish
} from "@/enums/editSpecifications.enum"
import { ISelectDropdownItem } from "@/interfaces/common.interface"
import React from "react"

export const MANUFACTURING_PROCESS_OPTIONS = [
  {
    id: EManufacturingProcess.SLS,
    title: "Selective Laser Sintering (SLS)",
    subTitle: "High strength industrial polymers for end-use parts",
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 })
  },
  {
    id: EManufacturingProcess.MJF,
    title: "Multi Jet Fusion (MJF)",
    subTitle: "High strength industrial polymers for production",
    prefix: React.createElement("img", { src: MJFIcon, width: 24, height: 30 })
  },
  {
    id: EManufacturingProcess.SLA,
    title: "Stereolithography (SLA)",
    subTitle: "High detail resin with excellent finish",
    prefix: React.createElement("img", { src: SLAIcon, width: 24, height: 30 })
  },
  {
    id: EManufacturingProcess.FDM,
    title: "Fused Deposition Modeling (FDM)",
    subTitle: "High performance thermoplastics",
    prefix: React.createElement("img", { src: FDMIcon, width: 24, height: 30 })
  },
  {
    id: EManufacturingProcess.DMLS,
    title: "Direct Metal Laser Sintering (DMLS)",
    subTitle: "High strength metal 3D printing",
    prefix: React.createElement("img", { src: DMLSIcon, width: 24, height: 30 })
  }
]

export const MATERIAL_OPTIONS = [
  {
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 }),
    id: EMaterial.NYLON_PA12,
    title: "Nylon PA12",
    subTitle: "Highly durable polyamide with excellent accuracy an..."
  },
  {
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 }),
    id: EMaterial.NYLON_PA12_ESD,
    title: "Nylon PA12 ESD",
    subTitle: "PA12 polyamide with electrostatic dissipative coating."
  },
  {
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 }),
    id: EMaterial.GLASS_FILLED_NYLON,
    title: "Glass Filled Nylon",
    subTitle: "Glass filled PA12 for enhanced stiffness & strength"
  },
  {
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 }),
    id: EMaterial.TPU_88A,
    title: "TPU 88A",
    subTitle: "Functional thermoplastic polyurethane at shore 88A."
  },
  {
    prefix: React.createElement("img", { src: SLSIcon, width: 24, height: 30 }),
    id: EMaterial.REQUEST_REVIEW,
    title: "Request Manual Review",
    subTitle: "Review my part and help me decide."
  }
]

export const COLOR_OPTIONS = [
  {
    prefix: React.createElement("img", { src: WhiteColor }),
    id: EColor.WHITE,
    title: "White",
    subTitle: "Raw material PA2200"
  },
  {
    prefix: React.createElement("img", { src: BlackColor }),
    id: EColor.BLACK_DYE,
    title: "Black Dye",
    subTitle: "Parts chemically finished in deep color dye"
  }
]

export const SURFACE_FINISH_OPTIONS = [
  {
    prefix: React.createElement("img", { src: StandardFinish }),
    id: ESurfaceFinish.STANDARD_FINISH,
    title: "Standard Finish",
    subTitle: "Parts are finishing with beadblasting"
  },
  {
    prefix: React.createElement("img", { src: VapourSmoothing }),
    id: ESurfaceFinish.VAPOUR_SMOOTHING,
    title: "Vapour Smoothing",
    subTitle: "Parts chemically finished"
  }
]

export const LAYER_HEIGHT_OPTIONS = [
  {
    id: ELayerHeight.normal,
    title: "80um"
  }
]

export const TOLERANCE_TEXT = {
  [EManufacturingProcess.SLS]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.25%, whichever is greater.",
    url: "https://forgelabs.com/sls-design-guide/"
  },
  [EManufacturingProcess.MJF]: {
    text: "Most parts will be printed within ±0.3 mm or ±0.3%, whichever is greater.",
    url: "https://forgelabs.com/mjf-design-guide/"
  },
  [EManufacturingProcess.SLA]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.25% (whichever is greater)",
    url: "https://forgelabs.com/sla-design-guide/"
  },
  [EManufacturingProcess.FDM]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.2%, whichever is greater.",
    url: "https://forgelabs.com/fdm-design-guide/"
  },
  [EManufacturingProcess.DMLS]: {
    text: "Parts will be printed within our standard tolerances: <100 mm: ±0.2 mm, <200 mm: ±0.3 mm, <300 mm: ±0.4 mm, 300–400 mm: ±0.5 mm",
    url: "https://forgelabs.com/dmls-design-guide/"
  },
  [EManufacturingProcess.DP]: {
    text: "",
    url: ""
  }
}
