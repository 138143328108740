import { DATE_RANGE_FORMATS } from "@/constants/common.constant"
import {
  Leadtime,
  LineItem,
  Product,
  QuoteResponse
} from "@/services/apiDigifabster/quote"
import {
  addDays,
  differenceInDays,
  format,
  isSameDay,
  isWeekend
} from "date-fns"
import Holidays, { HolidaysTypes } from "date-holidays"

export const getCanadianNationwideHolidays = (year: number) => {
  const hd = new Holidays("CA") // 'CA' is the country code for Canada
  const allHolidays = hd.getHolidays(year)

  const nationwideHolidays = allHolidays.filter((holiday) => {
    return holiday.type === "public" // type 'public' means it's a nationwide holiday
  })

  return nationwideHolidays
}

export const calculateDeliveryDate = (
  deliveryDays: number,
  currentDate: Date = new Date(),
  arg: { isRawDate?: boolean } = {}
) => {
  const { isRawDate = false } = arg

  const year = currentDate.getFullYear()
  const nationwideHolidays = getCanadianNationwideHolidays(year).map(
    (holiday) => new Date(holiday.date)
  )

  let deliveryDate = new Date(currentDate) // Start with the current date
  let daysAdded = 0

  // Add delivery days, skipping weekends and holidays
  while (daysAdded < deliveryDays) {
    deliveryDate = addDays(deliveryDate, 1) // Move to the next day

    // Check if the delivery date is a weekend or a holiday
    const isHoliday = nationwideHolidays.some((holiday) =>
      isSameDay(holiday, deliveryDate)
    )

    if (!isWeekend(deliveryDate) && !isHoliday) {
      daysAdded++
    }
  }

  // Make sure the delivery date is not a weekend or a holiday
  while (
    isWeekend(deliveryDate) ||
    nationwideHolidays.some((holiday) => isSameDay(holiday, deliveryDate))
  ) {
    deliveryDate = addDays(deliveryDate, 1)
  }

  return isRawDate
    ? deliveryDate.toDateString()
    : format(deliveryDate, "MM/dd/yyyy")
}

function isValidDate(date: any): boolean {
  return date instanceof Date && !isNaN(date.getTime())
}

// compare total of DAYS + POST_PRODUCTION and get the max one
export const getLatestDeliveryDate = (lineItems: LineItem[] | undefined) => {
  if (!lineItems) return
  let currentLatestDate = 0
  lineItems?.forEach((item) => {
    const leadTime = item?.config?.lead_time?.days
    let totalPostProductionDays = 0
    if (item?.config?.post_production) {
      totalPostProductionDays = item?.config?.post_production.reduce(
        (acc, cur) => acc + cur.production_days,
        0
      )
    }

    const deliveryDate = leadTime + totalPostProductionDays
    if (deliveryDate > currentLatestDate) {
      currentLatestDate = deliveryDate
    }
  })
  return currentLatestDate
}

// compare all days of lead_time and get the max one
export const getLatestDeliveryDays = (lineItems: LineItem[] | undefined) => {
  if (!lineItems) return
  let currentLatestDate = 0
  lineItems?.forEach((item) => {
    const leadTime = item?.config?.lead_time?.days

    const deliveryDate = leadTime
    if (deliveryDate > currentLatestDate) {
      currentLatestDate = deliveryDate
    }
  })
  return currentLatestDate
}

export const combineAllLeadTimeProducts = (
  quote: QuoteResponse | undefined
) => {
  const allProducts: Product[] = []
  quote?.products?.map((product) => {
    const currentLeadTime = product
    allProducts.push(currentLeadTime)
  })
  return allProducts as unknown as LineItem[]
}

// compare all name_for_user of lead_time and get the max one
export const getLatestLeadTimeForUser = (lineItems: LineItem[] | undefined) => {
  if (!lineItems) return ""
  let currentLatestDays = 0
  let currentNameForUser = ""
  lineItems?.forEach((item) => {
    const leadTime = item?.config?.lead_time?.name_for_user
    const matches = leadTime?.match(/\d+/g)

    if (matches && matches.length === 2) {
      const secondNumber = parseInt(matches[1], 10)
      if (secondNumber > currentLatestDays) {
        currentLatestDays = secondNumber
        currentNameForUser = leadTime
      }
    }
  })
  return currentNameForUser
}

// get 2 days in a string, minus the first number with the second number and return the result
export const getDiffDeliveryDays = (rangeOfDates: string) => {
  if (!rangeOfDates) {
    return 4
  }

  const matches = rangeOfDates?.match(/\d+/g)

  if (matches && matches.length === 2) {
    const firstNumber = parseInt(matches[0], 10)
    const secondNumber = parseInt(matches[1], 10)
    const result = secondNumber - firstNumber
    return result
  } else {
    return 4
  }
}

// format range of date become "EEE MMM d - EEE, MMM d"
// ex: "Mon Mar 21 - Fri, Mar 25"
export const formatRangeDeliveryDate = (
  daysDiff: number, // difference of 2 days
  additionalDays: number = 0,
  currentDate: Date = new Date(),
  dateRangeFormat: {
    from: string
    to: string
  } = {
    from: DATE_RANGE_FORMATS.shortWithNoComma,
    to: DATE_RANGE_FORMATS.shortWithComma
  }
) => {
  const lowestDateIgnoreHoliday = calculateDeliveryDate(
    additionalDays,
    currentDate,
    { isRawDate: true }
  )

  const highestDateIgnoreHoliday = calculateDeliveryDate(
    daysDiff,
    new Date(lowestDateIgnoreHoliday),
    { isRawDate: true }
  )
  let formattedLowestDate = ""
  let formattedHighestDate = ""
  if (isValidDate(new Date(lowestDateIgnoreHoliday))) {
    formattedLowestDate = format(
      new Date(lowestDateIgnoreHoliday),
      dateRangeFormat.from
    )
  } else {
    // Handle invalid lowestDateIgnoreHoliday
    console.error("Invalid lowestDateIgnoreHoliday:", lowestDateIgnoreHoliday)
  }

  if (isValidDate(new Date(highestDateIgnoreHoliday))) {
    formattedHighestDate = format(
      new Date(highestDateIgnoreHoliday),
      dateRangeFormat.to
    )
  } else {
    // Handle invalid highestDateIgnoreHoliday
    console.error("Invalid highestDateIgnoreHoliday:", highestDateIgnoreHoliday)
  }
  const result = `${formattedLowestDate} - ${formattedHighestDate}`
  return result
}

// Set outOfDate to the latest date in the delivery date range, then add 7 days.
// If now > outOfDate, recalculate the delivery date range based on the current date.
export const calcRangeDateAfter7Days = (
  range: string,
  lineItems: LineItem[] | undefined,
  date: string
) => {
  const getLatestDate = range.split("-")
  if (getLatestDate.length < 2 || !getLatestDate[1]) {
    console.error("Not get lastest day", getLatestDate)
  }
  const currentYear = new Date().getFullYear()

  const maxDay = new Date(`${getLatestDate[1]} ${currentYear}`)

  const outOfDate = addDays(maxDay, 7)

  if (!isValidDate(maxDay) || !isValidDate(outOfDate)) {
    console.error("Invalid maxDay or outOfDate:", maxDay, outOfDate)
  }

  const now = new Date()

  if (now > outOfDate) {
    // Get diffDay between now and outOfDate
    const diffDay = Math.abs(differenceInDays(now, outOfDate))
    return formatRangeDeliveryDate(
      getDiffDeliveryDays(getLatestLeadTimeForUser(lineItems)),
      getLatestDeliveryDate(lineItems),
      addDays(outOfDate, diffDay) // display the delivery date range base on current date
    )
  } else {
    return formatRangeDeliveryDate(
      getDiffDeliveryDays(getLatestLeadTimeForUser(lineItems)),
      getLatestDeliveryDate(lineItems),
      new Date(date)
    )
  }
}
