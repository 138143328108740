import { ImageProduct, ImageProduct2 } from "@/assets"
import { EMeasurement } from "@/enums/editSpecifications.enum"
import { ISelectDropdownItem } from "@/interfaces/common.interface"

export const measurementOptions: ISelectDropdownItem[] = [
  { id: EMeasurement.MM, title: "mm" },
  { id: EMeasurement.CM, title: "cm" },
  { id: EMeasurement.DM, title: "dm" }
]

export const MANUAL_REQUEST_CONFIG = {
  layer_thickness: "df31bbaa-ffbd-4b73-b693-35be9efae1e7",
  filling: "b6dac54d-85fb-4600-b22c-09f68a917f8c",
  lead_time: "977bd2a0-4ec1-483b-ac86-42f0b83078a4"
}

export const MANUAL_REQUEST_MATERIAL_ID = 66403
