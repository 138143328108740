import React from "react"
import "./HeadingCollapse.scss"
import { SuccessToastIcon } from "@/assets"
import Loading from "../common/atom/Loading/Loading"

interface IHeadingCollapseProps {
  prefix?: string
  title: string
  subtitle?: string
  isDone?: boolean
  isLoading?: boolean
}
const HeadingCollapse: React.FC<IHeadingCollapseProps> = ({
  prefix,
  title,
  subtitle,
  isDone = false,
  isLoading = false
}) => {
  return (
    <div className="heading-collapse-wrapper">
      <div className="heading-collapse-container">
        {prefix && <img src={prefix} />}
        <div className="">
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
      </div>

      <div className="heading-collapse-right">
        {isDone && (
          <img
            className="heading-collapse-right-done"
            height={20}
            width={20}
            src={SuccessToastIcon}
            alt=""
          />
        )}
        {isLoading && <Loading></Loading>}
      </div>
    </div>
  )
}

export default HeadingCollapse
