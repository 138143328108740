import React, { useState } from "react"
import "./MinusCheckbox.scss"
import { MinusSignIcon } from "@/assets"

const MinusCheckbox = ({
  isChecked,
  onDeselect,
  onSelect
}: {
  isChecked: boolean
  onDeselect: () => void
  onSelect: () => void
}) => {
  return (
    <div>
      <input
        type="checkbox"
        id="box"
        checked={isChecked}
        onChange={() => (isChecked ? onDeselect() : onSelect())}
        style={{ display: "none" }}
      />
      <label
        htmlFor="box"
        className={`minus-checkbox ${isChecked ? "checked" : ""}`}
      >
        <img src={MinusSignIcon} alt="-" />
      </label>
    </div>
  )
}

export default MinusCheckbox
