import React, { useEffect, useState } from "react"
import "./ShippingMethod.scss"
import { Button, RadioButton } from "@/components"
import { Radio, RadioChangeEvent } from "antd"
import MethodDelivery from "./components/MethodDelivery/MethodDelivery"
import MethodCollect from "./components/MethodCollect/MethodCollect"
import MethodLocalPickUp from "./components/MethodLocalPickUp/MethodLocalPickUp"
import { ECollapseKey, EShippingMethod } from "@/enums/checkout.enum"
import { ShippingRateResponse } from "@/services/apiDigifabster/shipping"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import { setQuoteShippingAndTaxPrice } from "@/store/quote"
import { useDispatch, useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { METHOD_SCHEMA } from "@/validations/method-shipping.validation"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { ICheckoutForm } from "@/interfaces/checkout.interface"
import _ from "lodash"
interface IShippingMethodProps {
  onChange: (data: {
    method: EShippingMethod
    carrier?: string
    note?: string
  }) => void
  shippingRate?: ShippingRateResponse[]
  taxRate?: number
  state?: string
  setRemovedKey: React.Dispatch<React.SetStateAction<ECollapseKey | undefined>>
  handlePreStep: () => void
  isLoading?: boolean
  prefillMethod: ICheckoutForm
}
const ShippingMethod: React.FC<IShippingMethodProps> = ({
  onChange,
  shippingRate,
  state,
  setRemovedKey,
  handlePreStep,
  isLoading,
  prefillMethod,
  taxRate
}) => {
  const prefillMethodData = {
    method: prefillMethod.shipping_method as EShippingMethod,
    carrier: prefillMethod.shipping_carrier,
    note: prefillMethod.shipping_note
  }
  const [data, setData] = useState<{
    method: EShippingMethod
    carrier?: string
    note?: string
  } | null>(prefillMethodData)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const methods = useForm({
    defaultValues: {
      accountNumber: userInfoNew?.last_shipping_method?.account_number
    },
    resolver: yupResolver(
      METHOD_SCHEMA(data?.method || EShippingMethod.DELIVERY)
    )
  })
  const { handleSubmit } = methods
  const dispatch = useDispatch()

  const onChangeCarrier = (value: string) => {
    setData((pre) => ({
      method: data?.method as EShippingMethod,
      ...pre,
      carrier: value
    }))
  }

  const onChangeNote = (noteValue: string) => {
    setData((pre) => ({
      method: data?.method as EShippingMethod,
      ...pre,
      note: noteValue
    }))
  }

  const onChangeValue = (e: RadioChangeEvent) => {
    setData({ method: e.target.value })
    if (e.target.value !== EShippingMethod.DELIVERY) {
      dispatch(setQuoteShippingAndTaxPrice({ shippingPrice: 0 }))
    }
  }

  useEffect(() => {
    if (
      !prefillMethod?.shipping_method ||
      prefillMethod?.shipping_method === EShippingMethod.DELIVERY
    ) {
      setData({
        method: EShippingMethod.DELIVERY,
        carrier: shippingRate?.[0]?.code
      })
    } else {
      setData(prefillMethodData)
    }
  }, [shippingRate, isLoading])

  useEffect(() => {
    if (!_.isEqual(data, prefillMethodData))
      setRemovedKey(ECollapseKey.SHIPPING_METHOD)
  }, [data])

  const getMethodUI = (value?: string) => {
    switch (value) {
      case EShippingMethod.DELIVERY:
        return (
          <MethodDelivery
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
            data={shippingRate || []}
            taxRate={taxRate}
            isPrefill={
              !!prefillMethod?.shipping_method &&
              prefillMethod?.shipping_method !== EShippingMethod.DELIVERY
            }
            isLoading={isLoading}
          />
        )
      case EShippingMethod.COLLECT:
        return (
          <MethodCollect
            prefillData={data?.carrier}
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
          />
        )
      case EShippingMethod.LOCAL_PICKUP:
        return <MethodLocalPickUp />
      default:
        return (
          <MethodDelivery
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
            data={shippingRate || []}
            taxRate={taxRate}
            isPrefill={
              !!prefillMethod?.shipping_method &&
              prefillMethod?.shipping_method !== EShippingMethod.DELIVERY
            }
          />
        )
    }
  }

  const handleNextStepClick = () => {
    if (
      data?.carrier ||
      (data?.method === EShippingMethod.LOCAL_PICKUP && data)
    ) {
      onChange(data)
    }
  }

  return (
    <FormProvider {...methods}>
      <div>
        <div className="shipping-method-box">
          <Radio.Group onChange={onChangeValue} value={data?.method}>
            <div className="shipping-method-box-detail">
              <RadioButton value={EShippingMethod.DELIVERY}>
                Delivery
              </RadioButton>
              <RadioButton value={EShippingMethod.COLLECT}>Collect</RadioButton>
              {state === "BC" && (
                <RadioButton value={EShippingMethod.LOCAL_PICKUP}>
                  In-Person Pick-up
                </RadioButton>
              )}
            </div>
          </Radio.Group>
          {getMethodUI(data?.method)}
        </div>
        <div className="collapse-footer">
          <Button
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={handlePreStep}
          >
            Previous Step
          </Button>
          <Button
            onClick={handleSubmit(handleNextStepClick)}
            customType={Type.NEUTRAL}
          >
            Next Step
          </Button>
        </div>
      </div>
    </FormProvider>
  )
}

export default ShippingMethod
