import { tooltipClickIcon, tooltipIcon } from "@/assets"
import { Tooltip } from "antd"
import React from "react"
import "./ToolTipDFM.scss"

interface ToolTipDFMProps {
  title: string
  isOpen: boolean
  isHover: boolean
  onVisibleChange: () => void
  onHoverToolTip: () => void
}
function ToolTipDFM({
  title,
  isOpen,
  onVisibleChange,
  onHoverToolTip,
  isHover
}: ToolTipDFMProps) {
  return (
    <Tooltip
      title={title}
      color="var(--bg-gray-strongest)"
      overlayClassName="custom-tooltip"
      overlayInnerStyle={{ padding: "var(--spacing-1) var(--spacing-1-5)" }}
      placement="top"
      arrowPointAtCenter
      open={isOpen}
      onOpenChange={(visible) => {
        if (!visible) {
          onVisibleChange()
        }
      }}
    >
      <img
        src={isHover ? tooltipClickIcon : tooltipIcon}
        onMouseOver={onHoverToolTip}
        alt=""
        className="img-tooltip"
      />
    </Tooltip>
  )
}

export default ToolTipDFM
