import { Flex } from "antd"
import "./NumberField.scss"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import Button from "../Button/Button"
import { useCallback, useEffect, useState } from "react"
import { debounce } from "@/utils/functionHelper"

interface INumberFieldProps {
  value: number
  setValue: (
    quantity: number,
    event?: React.ChangeEvent<HTMLInputElement>,
    options?: { isDebounce?: boolean }
  ) => void
  type?: Type.PRIMARY | Type.NEUTRAL
  hierarchy?: ButtonHierarchy
  size?: Size
  customClassName?: string
  min?: number
  disable?: boolean
  isNoneValue?: boolean
  isDebounce?: boolean
}

const NumberField = (props: INumberFieldProps): JSX.Element => {
  const {
    type = Type.PRIMARY,
    value,
    setValue,
    customClassName,
    size = Size.MEDIUM,
    min = 1,
    hierarchy = ButtonHierarchy.TONAL,
    disable,
    isNoneValue,
    isDebounce = true
  } = props
  const [count, setCount] = useState(value)
  const increase = () => {
    setValue(value + 1, undefined, { isDebounce: false }) // if click increase or decrease button, no need to debounce
    setCount(value + 1) // this state use for display newest value
  }

  const descrease = () => {
    if (value >= 1) {
      setValue(value - 1, undefined, { isDebounce: false })
      setCount(value - 1)
    }
  }

  const debounceFn = useCallback(
    debounce((event, data) => {
      setValue(data)
      event?.target?.blur()
    }, 1000),
    []
  )

  useEffect(() => {
    setCount(value)
  }, [value])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let data = Number(event.target.value)
    if (data < min) data = min

    setCount(data)
    isDebounce ? debounceFn(event, data) : setValue(data, event)
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      className={`number-field ${customClassName}`}
    >
      <Button
        onClick={descrease}
        hierarchy={hierarchy}
        customType={type}
        customSize={size}
        disabled={value <= min || disable}
      >
        <MinusOutlined />
      </Button>
      {isNoneValue ? (
        <p>--</p>
      ) : (
        <input
          className={disable ? "input-disabled" : ""}
          disabled={disable}
          value={count}
          type="number"
          onChange={handleChange}
        />
      )}

      <Button
        onClick={increase}
        hierarchy={hierarchy}
        customType={type}
        customSize={size}
        disabled={disable}
      >
        <PlusOutlined />
      </Button>
    </Flex>
  )
}

export default NumberField
