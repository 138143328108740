import { REQUIRED_ERROR_FIELD } from "@/constants/common.constant"
import { EShippingMethod } from "@/enums/checkout.enum"
import { object, string } from "yup"

export const METHOD_SCHEMA = (selectedMethod: EShippingMethod) => {
  return object().shape({
    accountNumber:
      selectedMethod === EShippingMethod.COLLECT
        ? string().required(REQUIRED_ERROR_FIELD())
        : string().notRequired()
  })
}
