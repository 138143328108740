import Modal from "../Modal/Modal"
import Button from "../common/atom/Button/Button"
import { Size } from "@/enums/common.enum"
import SelectDropdown from "../common/atom/SelectDropdown/SelectDropdown"
import { UNIT_OPTIONS } from "@/constants/unit.constant"
import "./ModalSelectCountry.scss"
import { closeModalIcon } from "@/assets"
import CountrySelect from "../common/atom/CountrySelect/CountrySelect"
import { useEffect, useState } from "react"
import { CountryOptions } from "@/interfaces/common.interface"
import {
  useLazyGetUserCountryQuery,
  useUpdateUserCountryMutation
} from "@/services/apiDigifabster/user"
import { useAppLoading } from "@/hooks/useLoading"

interface IModalSelectCountryProps {
  openModal: boolean
  closeModal: () => void
}

function ModalSelectCountry({
  openModal,
  closeModal
}: IModalSelectCountryProps) {
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [value, setValue] = useState<CountryOptions | undefined>(undefined)
  const [getCountry] = useLazyGetUserCountryQuery()
  const [updateUserCountry, { isLoading: updateCountryLoading }] =
    useUpdateUserCountryMutation()

  const acceptCountries = ["CA", "US"]

  const init = async () => {
    if (!countriesList || !countriesList.length) return

    const ipRes = await fetch("https://api.ipify.org/")
    const ip = await ipRes.text()
    const { data: getCountryData } = await getCountry(ip)
    if (getCountryData?.countryCode) {
      const country = countriesList.find(
        (e) => e.iso2 === getCountryData.countryCode
      )

      setValue(country)
    }
  }

  useEffect(() => {
    init()
  }, [countriesList])

  const handleDone = async () => {
    const iso2 = value?.iso2
    if (iso2) {
      await updateUserCountry({ country: iso2 })
    }
    closeModal()
  }

  return (
    <Modal
      title="Select your country"
      width={401}
      hideCancel
      footer={false}
      open={openModal}
      onCancel={closeModal}
      className="modal-resize-container"
      closeIcon={<img src={closeModalIcon}></img>}
      closable={false}
      maskClosable={false}
    >
      <div className="modal-resize-container-box">
        <div className="modal-resize-container-box-select">
          <CountrySelect
            countriesList={countriesList}
            setCountriesList={setCountriesList}
            flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
            width={"100%"}
            dropdownClassName="dropdown-menu"
            value={value?.id}
            handleChange={(countryId) => {
              setValue(countriesList.find((e) => e.id === countryId))
            }}
            acceptCountries={acceptCountries}
            placeholder="Select your country"
          />
        </div>
        <Button
          customSize={Size.LARGE}
          customClassName="modal-resize-container-box-btn"
          onClick={handleDone}
          disabled={!value?.iso2}
          loading={updateCountryLoading}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
}

export default ModalSelectCountry
