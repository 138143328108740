import ModalSelectCountry from "@/components/ModalSelectCountry/ModalSelectCountry"
import { useLoading } from "@/hooks/useLoading"
import {
  useLazyGetCompanyQuery,
  useLazyGetMeQuery,
  useLazyGetSettingsQuery
} from "@/services/apiDigifabster/user"
import { RootState } from "@/store"
import { IAuthStore, updateAppInit } from "@/store/auth"
import { IStorageState } from "@/store/storage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom"

const RootLayout: React.FC = () => {
  const { appInited } = useSelector<RootState, IAuthStore>((s) => s.auth)
  const { accessToken } = useSelector<RootState, IStorageState>(
    (s) => s.storage
  )
  const dispatch = useDispatch()
  const { openLoading, closeLoading } = useLoading()
  const [getUser] = useLazyGetMeQuery()
  const [getCompany] = useLazyGetCompanyQuery()
  const [getSettings] = useLazyGetSettingsQuery()
  const [openSelectCountry, setOpenSelectCountry] = useState(false)

  useEffect(() => {
    const init = async () => {
      openLoading()

      if (accessToken) {
        const { data } = await getUser()
        await getSettings()
        if (data?.organization?.id) {
          await getCompany()
        }
        if (data) {
          const { country } = data
          if (!country) {
            setOpenSelectCountry(true)
          }
        }
      }
    }

    init().finally(() => {
      closeLoading()
      dispatch(updateAppInit(true))
    })
  }, [accessToken])

  if (!appInited) {
    return <div />
  }

  return (
    <>
      <Outlet />
      <ModalSelectCountry
        openModal={openSelectCountry}
        closeModal={() => setOpenSelectCountry(false)}
      />
    </>
  )
}

export default RootLayout
