import { PayloadAction } from "@reduxjs/toolkit"
import { IAuthStore } from "."

export const authReducers = {
  updateAppInit: (state: IAuthStore, action: PayloadAction<boolean>) => {
    state.appInited = action.payload
  },
  updateAuthenticated: (state: IAuthStore, action: PayloadAction<boolean>) => {
    state.isAuthenciated = action.payload
  },
  setIsLogOut: (state: IAuthStore, action: PayloadAction<boolean>) => {
    state.isLogOut = action.payload
  }
}
