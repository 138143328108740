import { ActionReducerMapBuilder, isAnyOf } from "@reduxjs/toolkit"
import { IAuthStore } from "."
import { userService } from "@/services/apiDigifabster/user"

export const extraReducers = (
  builders: ActionReducerMapBuilder<IAuthStore>
) => {
  builders.addMatcher(
    isAnyOf(userService.endpoints.getMe.matchFulfilled),
    (state, _) => {
      state.isAuthenciated = true
    }
  )

  builders.addMatcher(userService.endpoints.getMe.matchRejected, (state, _) => {
    state.isAuthenciated = false
  })
}
