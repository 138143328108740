import {
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER,
  REGEX_PHONE_NUMBER_DASH,
  REQUIRED_ERROR
} from "@/constants/common.constant"
import { number, object, ref, string } from "yup"

export const MANUAL_REQUEST_SCHEMA = object({
  service: string().required(REQUIRED_ERROR("This field")),
  projectOverview: string().required(REQUIRED_ERROR("This field")),
  isHaveCADFile: string().required(REQUIRED_ERROR("This field")),
  budget: string().required(REQUIRED_ERROR("This field")),
  timeline: string().required(REQUIRED_ERROR("This field")),
  unit: string().required(REQUIRED_ERROR("This field"))
})

export const MANUAL_REQUEST_EXISTING_SCHEMA = object({
  description: string().required(REQUIRED_ERROR("This field"))
})
