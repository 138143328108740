import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { roundToTwoDecimal } from "@/utils/functionHelper"
import { useMemo } from "react"
import { useSelector } from "react-redux"

export const useCurrency = () => {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const { country, rate } = userInfoNew

  const currency = useMemo(() => {
    if (country === "US") return "$"
    return "C$"
  }, [country])

  return { currency }
}
