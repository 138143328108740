import "@google/model-viewer"
import { useMemo } from "react"

interface IModelProps {
  style?: React.CSSProperties
  src: string
  wallThicknessFileUrl?: string
}
const Model: React.FC<IModelProps> = ({ style, src, wallThicknessFileUrl }) => {
  const encodedSrc = encodeURIComponent(src)
  const encodedWallThicknessFileUrl = wallThicknessFileUrl
    ? encodeURIComponent(wallThicknessFileUrl)
    : ""
  const backgroundColor = encodeURIComponent("#f2f2f2")
  const modelColor = encodeURIComponent("#a0a0a0")
  const edgesColor = encodeURIComponent("#808080")
  const configModelBox = `3d_viewer-axes-shown=0&3d_viewer-floor_grid-shown=1&3d_viewer-model_box-shown=0&3d_viewer-printer_box-shown=0`
  const configDarkMode = `3d_viewer-darkmode-on=0`
  const configButton = `3d_viewer-darkmode_btn-shown=1&3d_viewer-axes_btn-shown=1&3d_viewer-floor_grid_btn-shown=1&3d_viewer-model_box_btn-shown=1&3d_viewer-printer_box_btn-shown=1`
  const configColor = `3d_viewer-model-color=${modelColor}&3d_viewer-model_edges-color=${edgesColor}&3d_viewer-scene_background-color=${backgroundColor}`
  const url = useMemo(() => {
    return `https://app.digifabster.com/forge-labs-inc/widget/model-viewer?modelFileUrl=${encodedSrc}${
      !!encodedWallThicknessFileUrl.length
        ? `&wallThicknessFileUrl=${encodedWallThicknessFileUrl}&${configModelBox}&${configDarkMode}&${configButton}&${configColor}`
        : `&${configModelBox}&${configDarkMode}&${configButton}&${configColor}`
    }`
  }, [src, wallThicknessFileUrl])
  return (
    <div id="card" style={{ height: "100%", width: "100%" }}>
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        src={url}
      />
    </div>
  )
}

export default Model
