import {
  REQUIRED_ERROR,
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER,
  REGEX_PHONE_NUMBER_DASH
} from "@/constants/common.constant"
import * as yup from "yup"

export const REGISTER_SCHEMA = yup.object().shape({
  firstName: yup.string().required(REQUIRED_ERROR("First name")),
  lastName: yup.string().required(REQUIRED_ERROR("Last name")),
  email: yup
    .string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format"),
  phone: yup
    .string()
    .required(REQUIRED_ERROR("Phone number"))
    .test("phone-number-max", "Phone number must be 10 digits", (value) => {
      return value ? value.replace(/[-\s]/g, "").length === 10 : false
    })
    .test("phone-number-format", "Invalid format", (value) => {
      return value ? REGEX_PHONE_NUMBER_DASH.test(value) : true
    }),
  password: yup
    .string()
    .required(REQUIRED_ERROR("Password"))
    .min(10, "Password must be at least 10 characters")
})
