import "./ModalWarning.scss"
import { Button, Modal } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"

interface IModalWarningProps {
  openModal: boolean
  closeModal: () => void
  onLeave: () => void
}

function ModalWarning({ openModal, closeModal, onLeave }: IModalWarningProps) {
  return (
    <Modal
      className="custom-modal-warning"
      open={openModal}
      onCancel={closeModal}
      title="Leave Checkout?"
      footer={false}
      width={401}
    >
      <p className="custom-modal-warning-title">
        Are you sure you want to leave ? All progress of current order will be
        lost.
      </p>
      <div className="custom-modal-warning-button">
        <Button customSize={Size.LARGE} onClick={onLeave}>
          Leave
        </Button>
        <Button
          onClick={closeModal}
          customType={Type.NEUTRAL}
          customSize={Size.LARGE}
          hierarchy={ButtonHierarchy.OUTLINE}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default ModalWarning
