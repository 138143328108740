import { noDataIcon } from "@/assets"
import "./EmptyTable.scss"
import { useLocation } from "react-router-dom"

function EmptyTable(): JSX.Element {
  const { pathname } = useLocation()
  const getTextOrderOrQuote = () => {
    if (pathname.includes("quotes")) return "quotes"
    if (pathname.includes("orders")) return "orders"
  }
  return (
    <div className="empty">
      <div className="empty-img">
        <img src={noDataIcon} alt="" />
      </div>
      <p className="empty-text">{`There are no ${getTextOrderOrQuote()} in this list yet`}</p>
    </div>
  )
}

export default EmptyTable
