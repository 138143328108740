import React from "react"
import Skeleton from "react-loading-skeleton"
import "./SingleSkeleton.scss"

const SingleSkeleton = ({
  width = "100%",
  height = 24
}: {
  width?: number | string
  height?: number
}) => {
  return (
    <Skeleton
      containerClassName="loading-skeleton"
      width={width}
      height={height}
    />
  )
}

export default SingleSkeleton
