import RootContainer from "@/components/RootContainer/RootContainer"
import UploadCADFile from "./components/UploadCADFile/UploadCADFile"
import "./SpecificationPage.scss"
import Toolbar from "./components/Toolbar/Toolbar"
import { useEffect, useMemo, useState } from "react"
import EditSpecificationDrawer from "./components/EditSpecificationDrawer/EditSpecificationDrawer"
import { useNavigate, useParams } from "react-router-dom"
import {
  cleanState,
  fileToProductDelete,
  IQuoteStore,
  setEditProduct,
  setIsUploading,
  setNewQuoteUploaded
} from "@/store/quote"
import { useDispatch, useSelector } from "react-redux"
import ProductItem from "./components/ProductItem/ProductItem"
import { useAppLoading, useLoading } from "@/hooks/useLoading"
import {
  ILocalFile,
  LineItem,
  useCreatePriceStartupCostMutation,
  useDeletePurchaseMutation,
  useLazyGetQuoteDetailQuery,
  useLazyGetQuoteQuery
} from "@/services/apiDigifabster/quote"
import { useCheckWallThickness, useSuitableMaterials } from "@/hooks"
import { RootState } from "@/store"
import { Drawer } from "@/components"
import { EModelAnalysisStatus, IProductStore } from "@/store/product"
import { EOrderStatus } from "@/enums/quotesList.enum"
import ModalManualRequest from "./components/ModalManualRequest/ModalManualRequest"
import { ConfigProvider, UploadFile } from "antd"
import { useLazyTechnologyListQuery } from "@/services/apiDigifabster/technology"
import { useUploadModels } from "@/hooks/useUploadModels"
import * as toast from "@/utils/Toast"
import { getLatestDeliveryDate } from "@/utils/timeFormatHelper"
import TopToolbar from "./components/TopToolbar/TopToolbar"
import { evaluateMatches } from "@/utils/arrayHelper"
import TopOrderToolbar from "./components/TopOrderToolbar/TopOrderToolbar"
import { SkeletonProductItem } from "@/components/Skeleton/SkeletonProductItem/SkeletonProductItem"

export default function SpecificationPage(): JSX.Element {
  const [selectedItems, setSelectedItems] = useState<LineItem[]>([])
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const { openLoading, closeLoading } = useLoading()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [getQuote, { isError, isFetching }] = useLazyGetQuoteQuery()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [manualFile, setManualFile] = useState<ILocalFile[]>([])
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const [getOrderDetail] = useLazyGetQuoteDetailQuery()
  const [getTechnologies, { isFetching: getTechnologiesLoading }] =
    useLazyTechnologyListQuery()

  const {
    currentQuote,
    selectedProduct,
    suitableMaterial,
    quoteDetail,
    newQuoteUploaded,
    modelsAnalysis
  } = useSelector<RootState, IQuoteStore & IProductStore>((s) => ({
    ...s.quote,
    ...s.product
  }))
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  let { quoteId } = useParams()
  const dispatch = useDispatch()

  const { uploadModels } = useUploadModels()

  const [deletePurchase] = useDeletePurchaseMutation()

  const navigate = useNavigate()
  useAppLoading([isFetching, getTechnologiesLoading])

  useSuitableMaterials()
  const { handleCheckWallThickness, isWallThickNessError, isProcessing } =
    useCheckWallThickness()

  useEffect(() => {
    if (technologies.length) return

    getTechnologies({ page: 1 })
  }, [technologies])

  useEffect(() => {
    dispatch(cleanState())
    if (quoteId) {
      getQuote({ quoteId })
    }
  }, [quoteId, getQuote])

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useEffect(() => {
    if (!currentQuote) return

    if (currentQuote.products && currentQuote.products.length > 0) {
      getOrderDetailApi(String(currentQuote?.id))
    }
  }, [currentQuote])

  useEffect(() => {
    if (!currentQuote?.id || !newQuoteUploaded.length || !technologies.length)
      return

    const uploadedFiles = newQuoteUploaded.filter(
      (f) => f.response?.orderId === currentQuote?.id
    )
    if (!uploadedFiles.length) return

    setFileList(uploadedFiles)
    dispatch(setNewQuoteUploaded([]))
    // TODO: handle upload
    dispatch(setIsUploading(true))
    uploadModels(uploadedFiles, "", {
      orderId: currentQuote.id,
      uploadJobId: currentQuote.upload_job,
      onlyPurchase: true,
      uploadInterupted(failedFile) {
        setFileList((pre) => {
          return pre.map<UploadFile>(
            (e) => failedFile.find((f) => f.uid === e.uid) || e
          )
        })
      }
    })
      .then(({ error, files: uploadedFiles }) => {
        if (error) {
          toast.showError(error)
        }
        setFileList((pre) => {
          return pre
            .map<UploadFile>((e) => uploadedFiles[e.uid] || e)
            .filter((e) => e.status !== "done")
        })
      })
      .finally(() => {
        dispatch(setIsUploading(false))
      })
  }, [newQuoteUploaded, currentQuote, technologies])

  const getOrderDetailApi = async (quoteId: string) => {
    const { data } = await getOrderDetail({ quoteId })
    const checkNullThumb = data?.line_items?.some(
      (item) => item.product?.thumb_status === "in_progress"
    )

    if (checkNullThumb) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          getOrderDetailApi(quoteId).then(res).catch(rej)
        }, 1000)
      })
    }
  }

  const handleSelectItem = (item: LineItem, selected: boolean) => {
    if (selected) {
      setSelectedItems((prevSelectedItems) => {
        const updatedSelection = [...prevSelectedItems, item]
        return updatedSelection
      })
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      )
    }
  }

  const handleCheckout = async () => {
    navigate(`/new-quote/checkout/${quoteId}`, { replace: true })
  }

  const handleEditItem = (id: number) => {
    const product = currentQuote?.products.find((e) => e.id === id)
    if (!product) return
    dispatch(setEditProduct(product))
    setOpenEditDrawer(true)
  }

  const handleCloseEditModal = () => {
    setOpenEditDrawer(false)
  }

  const handleSaveQuote = () => {
    openLoading()

    navigate("/new-quote/save-quote-success")
    setTimeout(() => {
      closeLoading()
    }, 2000)
  }

  const openModalManualRequest = () => {
    setIsOpenModal(true)
  }

  const closeModalManualRequest = () => {
    setIsOpenModal(false)
  }

  const handleDeleteItem = async () => {
    setIsLoadingDelete(true)

    const idsToRemove: number[] = [] // Collect the IDs of successfully deleted items

    for (const item of selectedItems) {
      try {
        await deletePurchase({
          orderId: currentQuote?.id || 0,
          purchaseId: item.id
        })
        idsToRemove.push(item.id)
      } catch (_) {}
    }

    // Remove the successfully deleted items from the selected items
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter(
        (selectedItem) => !idsToRemove.includes(selectedItem.id)
      )
    )

    setIsLoadingDelete(false)
  }

  const isRequiresReview = useMemo(() => {
    return (
      currentQuote?.available_order_initial_statuses?.includes(
        EOrderStatus.WAITING_FOR_REVIEW
      ) ||
      quoteDetail?.line_items?.some(
        (item) => item?.status === EOrderStatus.WAITING_FOR_REVIEW
      ) ||
      Object.values(modelsAnalysis)?.some(
        (e) => e?.status === EModelAnalysisStatus.ERROR
      )
    )
  }, [currentQuote, quoteDetail, modelsAnalysis])
  if (!currentQuote || isFetching || getTechnologiesLoading) return <></>

  return (
    <RootContainer
      onBack={() => navigate("/new-quote")}
      headerText="Order Configuration"
      subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
      customRightElement={
        <TopOrderToolbar
          data={currentQuote?.products || []}
          onCheckOut={handleCheckout}
          onSaveQuote={handleSaveQuote}
          isRequiresReview={isRequiresReview}
          DFMError={isWallThickNessError}
          onSubmitManualRequest={openModalManualRequest}
        />
      }
    >
      <div className="container-spec">
        <TopToolbar
          isEditable={
            evaluateMatches(quoteDetail?.line_items, selectedItems).editable
          }
          isDeletable={
            evaluateMatches(quoteDetail?.line_items, selectedItems).deletable
          }
          onSubmitManualRequest={openModalManualRequest}
          openDrawer={() => handleEditItem(selectedItems[0].id)}
          onDelete={handleDeleteItem}
        />
        <Toolbar
          isSelectedAll={
            evaluateMatches(quoteDetail?.line_items, selectedItems).allMatch
          }
          deselectAll={() => setSelectedItems([])}
          isSelectedNone={selectedItems.length === 0}
          selectAll={() => setSelectedItems(quoteDetail?.line_items || [])}
        />

        {(quoteDetail?.line_items || []).map((item) =>
          item?.local_file ? (
            <SkeletonProductItem
              key={item.local_file.id}
              file={item.local_file as any}
              onOpenManualRequest={() => {
                openModalManualRequest()
                setManualFile([item.local_file as any])
              }}
              onDelete={() =>
                dispatch(fileToProductDelete(String(item.local_file?.id)))
              }
            />
          ) : (
            <ProductItem
              key={item.id}
              item={item}
              isSelected={selectedItems?.some(
                (selectedItem) => selectedItem.id === item.id
              )}
              onSelect={(selected) => handleSelectItem(item, selected)}
              onEditItem={handleEditItem}
              isEditable={
                !!suitableMaterial[
                  item?.product?.parent_model || item?.product?.id
                ] &&
                (!isLoadingDelete || !selectedItems?.includes(item))
                // edit available when no loading and
                // if loading, the item wasn't selected
              }
              isProcessing={isProcessing}
              openDrawer={handleEditItem}
              deliveryDays={getLatestDeliveryDate(quoteDetail?.line_items)}
            />
          )
        )}
        <div className="upload-box">
          <UploadCADFile fileList={fileList} setFileList={setFileList} />
        </div>
        {/* <OrderToolbar
          data={currentQuote?.products || []}
          onCheckOut={handleCheckout}
          onSaveQuote={handleSaveQuote}
          isRequiresReview={
            currentQuote?.available_order_initial_statuses?.[0] ===
            EOrderStatus.WAITING_FOR_REVIEW
          }
          DFMError={isWallThickNessError || isProcessing}
          onSubmitManualRequest={openModalManualRequest}
        /> */}
        {
          <ConfigProvider
            theme={{
              token: {
                fontFamily: '"Open Sans", sans-serif'
              }
            }}
          >
            <Drawer
              className="edit-specification-drawer"
              width={420}
              open={openEditDrawer}
              title="Edit Specifications"
              onClose={handleCloseEditModal}
            >
              <div className="edit-specification-drawer-infor">
                <div className="edit-specification-drawer-infor-wrapper">
                  <img
                    src={selectedProduct?.model_thumbnail}
                    className="edit-specification-drawer-infor-img"
                    alt=""
                  />
                  <div className="edit-specification-drawer-infor-title">
                    <h3 className="">{String(selectedProduct?.model_title)}</h3>
                    <h5 className="">Product Description</h5>
                  </div>
                </div>
              </div>
              <EditSpecificationDrawer
                onUpdateDone={handleCloseEditModal}
                openEditDrawer={openEditDrawer}
              />
            </Drawer>
          </ConfigProvider>
        }
        <ModalManualRequest
          openModal={isOpenModal}
          closeModal={closeModalManualRequest}
          currentQuote={currentQuote}
          prefillFile={manualFile}
        />
      </div>
    </RootContainer>
  )
}
