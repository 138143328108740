type ItemWithId = { id: number | string }

// compare two arrays of objects with id property and return how many matches are there
export const evaluateMatches = <T extends ItemWithId>(
  base_array?: T[],
  selected_array?: T[]
): {
  allMatch: boolean
  moreThanOne: boolean
  oneMatch: boolean
  editable: boolean
  deletable: boolean
} => {
  if (!base_array?.length || !selected_array?.length) {
    return {
      allMatch: false,
      moreThanOne: false,
      oneMatch: false,
      editable: false,
      deletable: false
    }
  }

  const matchCount = base_array.filter((item1) =>
    selected_array.some((item2) => item1.id === item2.id)
  ).length

  return {
    allMatch:
      matchCount === base_array.length && matchCount === selected_array.length,
    moreThanOne: matchCount > 1 && matchCount < base_array.length,
    oneMatch: matchCount === 1,
    editable: matchCount === 1,
    deletable: matchCount >= 1
  }
}
