import {
  ICompanyStore,
  IGetUserCountryResponse,
  ISettingsResponse,
  IUpdateUserCountryPayload,
  IUpdateUserInfoPayload,
  IUpdateUserInfoResponse,
  IUpdateUserPasswordPayload,
  UserInfoResponse
} from "@/store/user/type"
import { api } from "../apiInstance"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMe: build.query<UserInfoResponse, void>({
      query: () => ({
        url: `/api/dgfc/user/me/`,
        method: "GET"
      })
    }),
    getSettings: build.query<ISettingsResponse, void>({
      query: () => ({
        url: `/api/dgfc/user/settings/`,
        method: "POST"
      })
    }),
    updateUserInfo: build.mutation<
      IUpdateUserInfoResponse,
      IUpdateUserInfoPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/user/update/`,
        method: "PATCH",
        body: queryArg
      })
    }),
    updatePassword: build.mutation<void, IUpdateUserPasswordPayload>({
      query: (queryArg) => ({
        url: `/api/dgfc/user/update-password/`,
        method: "POST",
        body: queryArg
      })
    }),
    getUserCountry: build.query<IGetUserCountryResponse, string>({
      query: (queryArg) => ({
        url: `/api/dgfc/user/location/${queryArg}`,
        method: "GET"
      })
    }),
    updateUserCountry: build.mutation<void, IUpdateUserCountryPayload>({
      query: (queryArg) => ({
        url: `/api/dgfc/user/update-country/`,
        method: "POST",
        body: queryArg
      })
    }),
    getCompany: build.query<ICompanyStore, void>({
      query: () => ({
        url: `/api/dgfc/user/company/`,
        method: "GET"
      })
    }),
    updateBillingAddressCompany: build.query<ICompanyStore, any>({
      query: (queryArg) => ({
        url: `/api/dgfc/user/update-billing-address-company/`,
        method: "POST",
        body: queryArg
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as userService }

export const {
  useGetMeQuery,
  useLazyGetMeQuery,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useUpdateUserInfoMutation,
  useUpdatePasswordMutation,
  useLazyGetUserCountryQuery,
  useUpdateUserCountryMutation,
  useLazyGetCompanyQuery,
  useLazyUpdateBillingAddressCompanyQuery
} = injectedRtkApi
