import { Outlet } from "react-router-dom"
import "./MainLayoutWithoutAuth.scss"
import SideBar from "@/components/common/SideBar/SideBar"
import { TABS } from "@/constants/common.constant"

const MainLayoutWithoutAuth: React.FC = () => {
  return (
    <div className="layout flex h-screen bg-no-repeat">
      <SideBar tabs={TABS} />
      <main className="layout-content overflow-auto h-full w-full">
        <Outlet />
      </main>
    </div>
  )
}

export default MainLayoutWithoutAuth
