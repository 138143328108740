import { Form, Upload, UploadFile, UploadProps } from "antd"
import "./OrderDetailSummary.scss"
import { Button, CustomBadge, FormField, TextField } from "@/components"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size,
  Type
} from "@/enums/common.enum"
import {
  DownloadIcon,
  downloadInvoiceIcon,
  paidIcon,
  PDFIcon,
  uploadPDFIcon,
  uploadPDFOrderPageIcon
} from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { debounce, priceDisplay } from "@/utils/functionHelper"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  useGetPaymentLinkMutation,
  useUpdateInvoiceMutation
} from "@/services/apiDigifabster/order"
import * as toast from "@/utils/Toast"
import {
  DIGIFABSTER_INVOICE_URL,
  errorStatus
} from "@/constants/common.constant"
import { useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useCurrency } from "@/hooks/useCurrency"
import { safeConcatUrl } from "@/utils/stringHelper"
import { CloseOutlined } from "@ant-design/icons"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { IInvoiceForm } from "@/interfaces/checkout.interface"
import { PO_SCHEMA } from "@/validations/credit.validation"

enum EStatusPayment {
  QUOTE = "quote",
  PO_PROVIDE = "po_provide",
  READY = "ready",
  PAID = "paid",
  QUOTE_SENT = "firm_offer_sent",
  PO_PAYMNET_DUE = "po_payment_due"
}
const statusPayment = {
  [EStatusPayment.QUOTE]: {
    title: "Preliminary Quote",
    classname: "invoice-summary-box-header-status-quote"
  },
  [EStatusPayment.PO_PROVIDE]: {
    title: "PO provided",
    classname: ""
  },
  [EStatusPayment.READY]: {
    title: "Ready for payment",
    classname: ""
  },
  [EStatusPayment.QUOTE_SENT]: {
    title: "Quote Sent",
    classname: ""
  },
  [EStatusPayment.PO_PAYMNET_DUE]: {
    title: "Payment due",
    classname: ""
  },
  [EStatusPayment.PAID]: {
    title: "Paid",
    classname: "invoice-summary-box-header-status-paid"
  }
}

const getFileName = (urlString: string) => {
  if (urlString && urlString.startsWith("http")) {
    try {
      const url = new URL(urlString)
      return url.pathname.split("/").pop()
    } catch (error) {
      return ""
    }
  } else {
    return
  }
}

function OrderDetailSummary() {
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  const [updateInvoice, { isLoading: isLoadingUpdateInvoice }] =
    useUpdateInvoiceMutation()
  const order = invoiceDetail?.order
  const isPaid = invoiceDetail?.is_paid
  const { currency } = useCurrency()
  const navigate = useNavigate()
  const [listFiles, setListFiles] = useState<UploadFile[]>([])
  const { invoice_hash } = useParams()

  useAppLoading([isLoadingUpdateInvoice])
  const {
    control,
    formState: { errors, isDirty },
    trigger,
    setValue,
    watch,
    setError
  } = useForm<IInvoiceForm>({
    defaultValues: {
      poNumber: order?.po_number,
      poFile: []
    },
    resolver: yupResolver(PO_SCHEMA),
    mode: "onChange"
  })

  const poNumber = watch("poNumber")
  const poFile = watch("poFile")

  const debounceFn = useCallback(
    debounce((onChange: () => void) => {
      onChange()
    }, 800),
    []
  )

  const validateAndCheckPO = async () => {
    const result = await trigger()
    if (
      result &&
      poNumber &&
      (poNumber !== order?.po_number ||
        getFileName(order?.attached_po || "") !== listFiles[0]?.name)
    ) {
      try {
        const uploadPayload = new FormData()
        uploadPayload.append("po_number", poNumber || "")
        if (poFile.length > 0 && poFile[0].originFileObj) {
          uploadPayload.append(
            "attached_po",
            poFile[0].originFileObj as any,
            poFile[0].name
          )
        }
        const payloadUpdateInvoice = {
          invoice_id: Number(invoiceDetail.id),
          invoice_hash: invoice_hash || "",
          arg: uploadPayload
        }
        const { data, error } = await updateInvoice(payloadUpdateInvoice)
        if (!data && error) throw new Error()
      } catch (err) {
        console.error("Failed to check PO number", err)
        toast.showError("Updated invoice fail")
      }
    }
  }

  useEffect(() => {
    if (isDirty) debounceFn(validateAndCheckPO)
  }, [poNumber, poFile])

  useEffect(() => {
    setValue("poNumber", order?.po_number)
    const prefillFiles = getFileName(order?.attached_po || "")?.length
      ? [
          {
            name: getFileName(order?.attached_po || ""),
            type: "application/pdf"
          } as UploadFile
        ]
      : []
    setListFiles(prefillFiles)
    setValue("poFile", prefillFiles, { shouldValidate: false })
    if (!order?.attached_po) setListFiles([])
  }, [invoiceDetail])

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setListFiles([file])
    const isPDF = file.type === "application/pdf"

    if (!isPDF) {
      setError("poFile", {
        type: "isValid",
        message: "Only PDF file is allowed."
      })
    }
    setValue("poFile", [file], { shouldValidate: true, shouldDirty: true })
  }

  const handleRemoveFile = (file: UploadFile) => {
    const updatedFiles = listFiles.filter((item) => item.uid !== file.uid)
    setListFiles(updatedFiles)
    setValue("poFile", [], {
      shouldValidate: true,
      shouldDirty: !!listFiles?.length
    })
  }

  const statusPaid = useMemo(() => {
    if (!isPaid) {
      switch (order?.status) {
        case EOrderStatus.WAITING_FOR_REVIEW:
          return EStatusPayment.QUOTE
        case EOrderStatus.FIRM_OFFER_SENT:
          return EStatusPayment.QUOTE_SENT
        case EOrderStatus.PO_PROVIDED:
          return EStatusPayment.PO_PROVIDE
        case EOrderStatus.PO_PAYMNET_DUE:
          return EStatusPayment.PO_PAYMNET_DUE
        default:
          return EStatusPayment.READY
      }
    }
    return EStatusPayment.PAID
  }, [invoiceDetail, order?.status])

  const getStatusPaymentColor = (status: string) => {
    const transformStatus = status.replace(status[0], status[0].toLowerCase())
    if (transformStatus === EStatusPayment.PAID) return BadgeColor.SUCCESS
    return BadgeColor.ROLE
  }

  return (
    <Form>
      <div className="order-detail-summary">
        <div className="order-detail-summary-box">
          <div className="order-detail-summary-box-header">
            <div className="order-detail-summary-box-header-layout">
              <p className="order-detail-summary-box-header-layout-title">
                Total
              </p>
              <CustomBadge
                content={statusPayment?.[statusPaid].title}
                size={Size.MEDIUM}
                type={BadgeType.SOLID}
                color={getStatusPaymentColor(statusPayment?.[statusPaid].title)}
                prefix={<img src={paidIcon} style={{ display: "flex" }}></img>}
              ></CustomBadge>
            </div>
          </div>
          <div className="order-detail-summary-box-content">
            <div className="order-detail-summary-box-content-price">
              <p>Subtotal</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.models_naked_price)}
              </p>
            </div>
            {invoiceDetail?.order?.startup_cost > 0 && (
              <div className="order-detail-summary-box-content-price">
                <p>Minimum Order Fee</p>

                <p>
                  {currency}
                  {priceDisplay(invoiceDetail?.order?.startup_cost || 0)}
                </p>
              </div>
            )}
            {Number(invoiceDetail?.order?.price_corrections_cost) > 0 && (
              <div className="order-detail-summary-box-content-price">
                <p>Additional Services</p>

                <p>
                  {currency}
                  {priceDisplay(
                    Number(invoiceDetail?.order?.price_corrections_cost) || 0
                  )}
                </p>
              </div>
            )}
            <div className="order-detail-summary-box-content-price">
              <p>Delivery</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.delivery_price)}
              </p>
            </div>
            <div className="order-detail-summary-box-content-price">
              <p>Tax</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.tax_value)}
              </p>
            </div>
          </div>
          <div className="order-detail-summary-box-total">
            <div className="order-detail-summary-box-total-price">
              <p>Total</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.total_price)}
              </p>
            </div>
          </div>
        </div>
        <div className="order-detail-summary-po">
          <FormField
            textLabel="Purchase Order"
            errorText={isDirty ? errors.poNumber?.message : ""}
            clasName="payment-method-right-po"
          >
            <Controller
              name="poNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="-"
                  {...field}
                  status={errorStatus(isDirty ? errors.poNumber : undefined)}
                />
              )}
            />
          </FormField>
          <FormField
            textLabel="Purchase Order Document"
            errorText={errors.poFile?.message}
          >
            <div
              className={`payment-method-right-content ${
                errors.poFile?.message
                  ? "payment-method-right-content-error"
                  : ""
              }`}
            >
              {!!listFiles.length ? (
                <CustomBadge
                  content={""}
                  size={Size.SMALL}
                  type={BadgeType.TONAL}
                  color={BadgeColor.ROLE}
                >
                  <div className="item-render-pdf">
                    <img src={PDFIcon} alt="" />
                    <p className="item-name-file">{listFiles[0]?.name}</p>
                    <div className="icon-box">
                      <CloseOutlined
                        onClick={() => handleRemoveFile(listFiles[0])}
                        className="icon-box-render"
                      />
                    </div>
                  </div>
                </CustomBadge>
              ) : (
                <p></p>
              )}
              <Upload
                accept="*/*"
                onChange={handleChangeFile}
                fileList={[]}
                showUploadList={false}
              >
                <Button
                  customSize={Size.EXTRA_SMALL}
                  hierarchy={ButtonHierarchy.LINK}
                  customType={Type.NEUTRAL}
                  customClassName="upload-btn"
                >
                  <img src={uploadPDFOrderPageIcon} alt="" />
                  <p className="title-upload">
                    {!!listFiles.length ? "Replace" : "Upload PDF"}
                  </p>
                </Button>
              </Upload>
            </div>
          </FormField>
        </div>
        <div className="order-detail-summary-footer">
          <Button
            hierarchy={ButtonHierarchy.OUTLINE}
            customSize={Size.LARGE}
            customClassName="invoice-btn"
            onClick={() => {
              window.open(
                invoiceDetail?.pdf_url.replace(
                  DIGIFABSTER_INVOICE_URL,
                  safeConcatUrl(
                    String(process.env.REACT_APP_BACKEND_ENDPOINT),
                    "invoice"
                  ) || ""
                ) || "",
                "_blank"
              )
            }}
          >
            <img src={downloadInvoiceIcon} alt="" />
            Download Invoice
          </Button>
          <p>
            Have Questions?&nbsp;
            <a href={`mailto:${settings.support_email}`}>Ask for help</a>
          </p>
        </div>
      </div>
    </Form>
  )
}

export default OrderDetailSummary
