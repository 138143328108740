import "../PostProductionDropdown/PostProductionDropdown.scss"
import { Select, SelectProps } from "antd"
import { priceDisplay } from "@/utils/functionHelper"
import { Button, CustomCheckbox, NumberField } from "@/components"
import { IMaterialConfig } from "@/store/product"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { IPostProductionData } from "@/interfaces/editSpecifications.interface"
import { useEffect, useMemo, useState } from "react"
import { replacePostProductionTitle } from "@/utils/stringHelper"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"

export interface SelectDropdownProps extends SelectProps {
  options: IMaterialConfig[]
  value?: IPostProductionData[]
  currency: string
  onChangePostProduction: (newPostProductionData: IPostProductionData[]) => void
}

interface IValue extends IMaterialConfig {
  quantity: number
}

const PostProductionDropdown: React.FC<SelectDropdownProps> = ({
  options,
  placeholder = "Select",
  defaultValue,
  value,
  currency,
  onChangePostProduction,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [uuid: string]: IValue
  }>({})
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const customTagRender = (props: { label: string }) => {
    const { label } = props

    return <>{label}</>
  }

  useEffect(() => {
    value?.map((item) => {
      if (options.some((option) => option.uuid === item.uuid)) {
        setSelectedOptions((prev: any) => {
          const newOptions = { ...prev }
          newOptions[item.uuid] = { quantity: item.quantity, ...item }
          return newOptions
        })
      }
    })
  }, [])

  const handleCheckboxChange = (item: IMaterialConfig) => {
    setSelectedOptions((prev: any) => {
      const newOptions = { ...prev }
      if (newOptions[item.uuid]) {
        delete newOptions[item.uuid]
      } else {
        newOptions[item.uuid] = { quantity: 1, ...item }
      }
      return newOptions
    })
  }

  const handleQuantityChange = (item: IMaterialConfig, quantity: number) => {
    setSelectedOptions((prev: any) => {
      const newOptions = { ...prev }
      if (newOptions[item.uuid]) {
        newOptions[item.uuid].quantity = quantity
      }
      return newOptions
    })
  }

  const handleDone = () => {
    const cloneData = { ...selectedOptions }
    const selectedOptionsArray = Object.values(cloneData)
    const mappedData = selectedOptionsArray.map((item) => {
      return {
        uuid: item.uuid,
        quantity: item.quantity
      }
    }) as IPostProductionData[]
    const defaultData = value || []

    const concatData = [...defaultData, ...mappedData]

    const uniqueData = Array.from(
      new Map(concatData.map((item) => [item.uuid, item])).values()
    )

    const dataNotInOption = uniqueData.filter((item) => {
      return !options.some((option) => option.uuid === item.uuid)
    })

    const postProductionData = [...dataNotInOption, ...mappedData]

    onChangePostProduction(postProductionData)
    setDropdownOpen(false)
  }

  const getDisplayText = useMemo(() => {
    const selectedText = Object.entries(selectedOptions).map(
      ([uuid, option]) => {
        return option.countable
          ? `+ ${replacePostProductionTitle(
              option.title,
              userInfoNew?.country
            )} (${option?.quantity})`
          : `+ ${replacePostProductionTitle(
              option.title,
              userInfoNew?.country
            )}`
      }
    )
    return selectedText.join(", ") || ""
  }, [selectedOptions])

  const dropdownRender = () => (
    <div>
      {options.map((item) => {
        return (
          <div key={item.uuid}>
            <div className="edit-specification-form-post">
              <CustomCheckbox
                value={item.uuid}
                checked={!!selectedOptions[item.uuid]}
                size={Size.MEDIUM}
                onChange={(e) => handleCheckboxChange(item)}
              />

              <div className="edit-specification-form-post-text">
                <h5>{item.title}</h5>
                <p>{item.note_for_user}</p>
              </div>

              {item.countable && !!selectedOptions[item.uuid] && (
                <NumberField
                  type={Type.NEUTRAL}
                  hierarchy={ButtonHierarchy.LINK}
                  size={Size.SMALL}
                  value={selectedOptions[item.uuid]?.quantity || 1}
                  setValue={(newQuantity) =>
                    handleQuantityChange(item, newQuantity)
                  }
                />
              )}

              <div className="edit-specification-form-post-text post-price">
                <h5>
                  {currency}
                  {priceDisplay(item.price || 0)}
                </h5>
              </div>
            </div>
          </div>
        )
      })}
      <div className="post-production-dropdown-menu-done-btn">
        <Button onClick={handleDone} customSize={Size.EXTRA_SMALL}>
          Done
        </Button>
      </div>
    </div>
  )

  const handleDropdownVisibleChange = (open: any) => {
    setDropdownOpen(open)
    if (!open) {
      if (!value) return setSelectedOptions({})
      value?.map((item) => {
        if (options.some((option) => option.uuid === item.uuid)) {
          const data = options.find((option) => option.uuid === item.uuid)
          setSelectedOptions((prev: any) => {
            const newOptions = { ...prev }
            newOptions[item.uuid] = { quantity: item.quantity, ...data }
            return newOptions
          })
        } else {
          setSelectedOptions({})
        }
      })
    }
  }

  const isOptionsSelected = Object.keys(selectedOptions).length > 0
  return (
    <div className="post-production-select-wrapper">
      <Select
        {...props}
        className="post-production-select-dropdown"
        mode="multiple"
        showSearch={false}
        filterOption={false}
        placeholder={<div className="placeholder-text">{placeholder}</div>}
        style={{ width: "100%" }}
        labelInValue
        value={
          isOptionsSelected
            ? [{ label: getDisplayText, value: getDisplayText }]
            : []
        }
        open={dropdownOpen}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        popupClassName="post-production-dropdown-menu multiple"
        notFoundContent={<div className="not-found-text">No results found</div>}
        tagRender={(props) =>
          customTagRender({
            ...props,
            label: props.label as string
          })
        }
        dropdownRender={dropdownRender}
      ></Select>
    </div>
  )
}

export default PostProductionDropdown
