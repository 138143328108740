import { EColor } from "@/enums/editSpecifications.enum"
import {
  IMapSelectDropdownItem,
  IPhoneData,
  ISelectDropdownItem
} from "@/interfaces/common.interface"
import { Option } from "@/services/api/product"

export const mapItemsToSelectOptions = (items: ISelectDropdownItem[]) => {
  return items.map<IMapSelectDropdownItem>((item) => ({
    key: item.id,
    value: item.id,
    label: item.title,
    prefix: item?.prefix,
    subTitle: item?.subTitle,
    checked: item.checked || false,
    title: item.title,
    id: item.id
  }))
}

export const transformPhoneNumber = (phoneNumber: IPhoneData) => {
  const { phoneNum, countryCode } = phoneNumber
  if (!countryCode) return phoneNum
  const startIndex = phoneNum.indexOf("+") + 1 // Start after '+'
  const nonPlusIndex = phoneNum.slice(startIndex).search(/\d/) + startIndex

  const phoneNumberWithoutCountryCode = phoneNum
    .slice(nonPlusIndex)
    .replace(countryCode, "")
  return phoneNumberWithoutCountryCode
}

export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes}B`
  } else if (sizeInBytes < 1048576) {
    return `${(sizeInBytes / 1024).toFixed(2)}KB`
  } else {
    return `${(sizeInBytes / 1048576).toFixed(2)}MB`
  }
}

export const handleSelectChange = (
  value: string,
  setter: React.Dispatch<React.SetStateAction<string | undefined>>,
  onChange: (...event: any[]) => void
) => {
  setter(value)
  onChange(value)
}

export const findOptionId = (
  options: Array<{ id: number | string; title: string }>,
  value: Option
) => {
  return (
    options.find((item) => item?.id === value.id || item?.title === value.name)
      ?.id || 0
  )
}

export const findOptionTitle = (
  options: Array<{ id: string; title: string }>,
  value: number | string
) => {
  return transformText(options.find((item) => item.id === value)?.title || "")
}

export const transformText = (text: string) => {
  if (text === "Black Dye") return "Black"
  if (text.includes("(")) return text.split("(")?.[0].trim()
  return text
}

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined

  return (...args: Parameters<T>) => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      func(...args)
    }, wait)
  }
}

export const safeParseErrorJSON = (jsonString: string) => {
  if (!jsonString) return
  const detailCorrected = jsonString.replace(/'/g, '"')
  return JSON.parse(detailCorrected)
}

export const priceDisplay = (price: number) => {
  if (!price) return Number(0).toFixed(2)
  return price.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const chunkArray = (array: any[], chunkSize: number) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize)
    result.push(chunk)
  }
  return result
}

export function roundToTwoDecimal(num: number) {
  return Math.round(num * 100) / 100
}

export function convertToTitleCase(str: string) {
  if (!str) {
    return ""
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())
}

export const getTextShippingMethod = (str: string = ""): string => {
  const match = str?.match(/^([a-zA-Z]+):/)
  return match ? match[1] : ""
}
