import { QuoteStatus } from "@/enums/quotesList.enum"
import { differenceInDays, differenceInMinutes, format } from "date-fns"

export const mockQuotes = Array.from({ length: 100 }, (_, index) => {
  const status = QuoteStatus.QUOTE_SENT

  const day = Math.floor(Math.random() * 30) + 1
  const hour = Math.floor(Math.random() * 24)
  const minute = Math.floor(Math.random() * 60)

  const createdAtDate = new Date(
    `2024-07-${String(day).padStart(2, "0")}T${String(hour).padStart(
      2,
      "0"
    )}:${String(minute).padStart(2, "0")}:00Z`
  )

  return {
    quoteStatus: status,
    quoteId: `Q202308${String(index + 1).padStart(3, "0")}`,
    createdAt: createdAtDate.toISOString(),
    grossPrice: 500 + index * 50
  }
})

export const checkQuoteIsRemain = (day: number, hour: number) => {
  return day <= 0 && hour <= 0
}

export const calcHoursRemaining = (createdAt: string): number => {
  const now = new Date()
  const targetDate = new Date(createdAt)
  const expirationDate = new Date(targetDate)
  expirationDate.setDate(targetDate.getDate() + 30)

  const diffInMinutes = differenceInMinutes(expirationDate, now)

  const hours = Math.floor(diffInMinutes / 60) % 24

  return hours
}

export const calcDaysRemaining = (createdAt: string): number => {
  const now = new Date()
  const targetDate = new Date(createdAt)

  const daysSinceCreation = Math.abs(differenceInDays(targetDate, now))

  const timeRemaining = 30 - daysSinceCreation

  return timeRemaining
}

export const quotesListColumn = [
  {
    title: "#",
    dataIndex: "no",
    key: "no",
    width: "10%"
  },
  {
    title: "ORDER DATE",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (date: string) => format(date, "MM/dd/yyyy"),
    width: "25%"
  },
  {
    title: "TOTAL",
    dataIndex: "total",
    key: "total",
    width: "25%"
  }
]
