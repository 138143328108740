import { useNavigate } from "react-router-dom"
import "./MainLayout.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IAuthStore } from "@/store/auth"
import { useEffect } from "react"
import { getPresistStorage } from "@/utils/storageHelper"
import MainLayoutWithoutAuth from "../MainLayoutWithoutAuth/MainLayoutWithoutAuth"

const MainLayout: React.FC = () => {
  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenciated) return

    navigate("/auth/sign-in", { replace: true })
  }, [isAuthenciated])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      const persistStorageObj = getPresistStorage(event)
      if (!persistStorageObj) return

      const accessToken = persistStorageObj["accessToken"]
      if (!accessToken) {
        window.location.href = "/auth/sign-in"
      }
    }

    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  if (!isAuthenciated) return <></>

  return <MainLayoutWithoutAuth />
}

export default MainLayout
