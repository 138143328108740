import { api } from "../../apiInstance"
import { OrderDetailResponse } from "../quote"
import {
  ICreateInvoicePayload,
  ICreateInvoiceResponse,
  IGetInvoicePayload,
  IGetInvoiceResponse,
  IPaymentPayload,
  IPaymentResponse,
  ISubmitOrderPayload,
  ISubmitOrderResponse,
  IUpdateInvoicePayload,
  IUpdateInvoiceResponse,
  IUpdateOrderPayload,
  IUpdateOrderResponse
} from "./type"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createInvoice: build.mutation<
      ICreateInvoiceResponse,
      ICreateInvoicePayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.order_id}/submit_initial_order/`,
        method: "POST",
        body: argPayload.arg
      })
    }),
    updateInvoice: build.mutation<IGetInvoiceResponse, IUpdateInvoicePayload>({
      query: (argPayload) => ({
        url: `/api/dgfo/invoices/${argPayload.invoice_id}/${argPayload.invoice_hash}/`,
        method: "PATCH",
        body: argPayload.arg
      })
    }),
    submitOrder: build.mutation<ISubmitOrderResponse, ISubmitOrderPayload>({
      query: (argPayload) => ({
        url: `/api/dgfc/order/${argPayload.orderId}/quote/submit/`,
        method: "POST",
        body: argPayload.arg
      })
    }),
    submit: build.mutation<ISubmitOrderResponse, ISubmitOrderPayload>({
      query: (argPayload) => ({
        url: `/api/dgfc/order/${argPayload.orderId}/quote/submit-order/`,
        method: "POST",
        body: argPayload.arg
      })
    }),
    getPaymentLink: build.mutation<IPaymentResponse, IPaymentPayload>({
      query: (argPayload) => ({
        url: `/api/dgfc/order/${argPayload.orderId}/quote/payment-link/`,
        method: "POST"
      })
    }),
    updateOrder: build.mutation<IUpdateOrderResponse, IUpdateOrderPayload>({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.order_id}/`,
        method: "PATCH",
        body: argPayload.arg
      })
    }),
    getInvoice: build.query<IGetInvoiceResponse, IGetInvoicePayload>({
      query: (queryArg) => ({
        url: `/api/dgfo/invoices/${queryArg.id}/${queryArg.hash}/`
      })
    }),
    repeatOrder: build.mutation<OrderDetailResponse, any>({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.orderId}/repeat/`,
        method: "POST"
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as orderService }

export const {
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetPaymentLinkMutation,
  useUpdateOrderMutation,
  useSubmitOrderMutation,
  useSubmitMutation,
  useGetInvoiceQuery,
  useLazyGetInvoiceQuery,
  useRepeatOrderMutation
} = injectedRtkApi
