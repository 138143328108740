import { useEffect, useState } from "react"
import { ConfigProvider, Flex, Form } from "antd"
import "./LoginForm.scss"
import "@/assets/scss/_styles.scss"
import { Link, useNavigate } from "react-router-dom"
import { Button, TextField, FormField, CustomCheckbox } from "@/components"
import { Size } from "@/enums/common.enum"
import { Controller, FieldError, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { loginSchema } from "@/validations/login.validations"
import { useAppLoading } from "@/hooks/useLoading"
import { ErrorFieldIcon } from "@/assets"
import { useLoginMutation } from "@/services/apiDigifabster/auth"
import {
  useLazyGetMeQuery,
  useLazyGetSettingsQuery
} from "@/services/apiDigifabster/user"
import * as toast from "@/utils/Toast"
interface ILoginFormInput {
  email: string
  password: string
}

const LoginForm = (): JSX.Element => {
  const errorStatus = (errors?: FieldError) => (errors ? "error" : "")
  const [handleLogin, { isLoading, isSuccess, error }] = useLoginMutation()
  const [getUser] = useLazyGetMeQuery()
  const [getSettings] = useLazyGetSettingsQuery()
  const [errorMessage, setErrorMessage] = useState<string>("")
  useAppLoading([isLoading])
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading || !isSuccess) return

    // TODO: call getme
    getUser().then(() => {
      getSettings()
      navigate("/")
    })
  }, [isLoading, isSuccess])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginFormInput>({
    defaultValues: {
      email: "",
      password: ""
    },
    resolver: yupResolver(loginSchema)
  })

  useEffect(() => {
    if (error) {
      const errorData = error as any
      const messageError = errorData.data?.detail || ""
      if (messageError?.includes("Incorrect authentication credentials.")) {
        setErrorMessage("Password or email does not match")
      } else toast.showError(messageError?.message)
    }
  }, [error])

  const onChangeField = (
    onChange: (...event: any[]) => void,
    value: string
  ) => {
    onChange(value)
    setErrorMessage("")
  }
  const onSubmit = async (data: ILoginFormInput) => {
    handleLogin({
      email: data.email,
      password: data.password,
      company_name: process.env.REACT_APP_COMPANY_NAME || ""
    })
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", sans-serif'
        }
      }}
    >
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <div className="margin-bottom">
          <FormField textLabel="Email" errorText={errors.email?.message}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.email)}
                  {...field}
                  onChange={(e) =>
                    onChangeField(field.onChange, e.target.value)
                  }
                  placeholder="Enter your email address"
                />
              )}
            />
          </FormField>
          <FormField textLabel="Password" errorText={errors.password?.message}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.password)}
                  {...field}
                  onChange={(e) =>
                    onChangeField(field.onChange, e.target.value)
                  }
                  type="password"
                  placeholder="Enter password"
                />
              )}
            />
          </FormField>
          {errorMessage && (
            <span className="error">
              <img
                src={ErrorFieldIcon}
                alt="error-icon"
                className="error-icon"
              />
              <span className="error-text">{errorMessage}</span>
            </span>
          )}
        </div>
        <Flex justify="space-between">
          <Flex gap={8}>
            <CustomCheckbox></CustomCheckbox>
            <p className="text-checkbox">Remember me</p>
          </Flex>
          <p className="text-forgot-password">
            <Link to={"/auth/forgot-password"}>Forgot Password?</Link>
          </p>
        </Flex>
        <Form.Item>
          <Flex align="center" justify="center">
            <Button
              type="primary"
              htmlType="submit"
              customSize={Size.EXTRA_LARGE}
              customClassName="btn-login"
            >
              Login
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </ConfigProvider>
  )
}

export default LoginForm
