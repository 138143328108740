import { ConfigProvider, Divider, Flex } from "antd"
import LoginForm from "./components/LoginForm/LoginForm"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { IconGoogle } from "@/assets"
import { Link } from "react-router-dom"
import { useGoogleSignin } from "@/hooks/useOAuth"
import "./SignInPage.scss"

export default function SignInPage(): JSX.Element {
  const handleGoogleSignin = useGoogleSignin()

  return (
    <div className="login-form">
      <Flex justify="center" align="center" vertical style={{ height: "100%" }}>
        <h3 className="login-title">Login</h3>
        <LoginForm></LoginForm>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Open Sans", sans-serif'
            }
          }}
        >
          <Flex
            vertical
            style={{ width: "100%" }}
            justify="center"
            align="center"
            gap={20}
          >
            <div style={{ width: "50%" }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorSplit: "var(--border-gray-strong)",
                    colorText: "var(--text-gray-normal)",
                    margin: 0
                  }
                }}
              >
                <Divider plain>or</Divider>
              </ConfigProvider>
            </div>
            <Button
              customType={Type.NEUTRAL}
              hierarchy={ButtonHierarchy.OUTLINE}
              customSize={Size.EXTRA_LARGE}
              onClick={handleGoogleSignin}
              style={{ width: "50%" }}
            >
              <img src={IconGoogle} alt="" className="icon-google" />
              Login with Google
            </Button>
            <p>
              Don't you have an account?{" "}
              <span className="text-forgot-password">
                <Link to={"/auth/register"}>Sign up</Link>
              </span>
            </p>
          </Flex>
        </ConfigProvider>
      </Flex>
    </div>
  )
}
