import { Outlet } from "react-router-dom"
import "./ProfileLayout.scss"
import ProfileNavigation from "@/components/ProfileNavigation/ProfileNavigation"
import Header from "@/components/Header/Header"

const ProfileLayout: React.FC = () => {
  return (
    <div className="profile-layout">
      <div className="profile-layout-content">
        <Header>
          <h3 className="header-text">Profile</h3>
        </Header>
        <div className="content-wrapper">
          <div className="left">
            <ProfileNavigation />
          </div>
          <main className="content">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  )
}

export default ProfileLayout
