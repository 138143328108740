import { arrowBackIcon, loadingFileFailIcon, loadingFileIcon } from "@/assets"
import "./Uploading.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import UploadModelLoading from "@/assets/lottie/UploadModelLoading.json"

interface IUploadingProps {
  mode?: string
  onBackToHome: () => void
}

function Uploading({ mode = "pending", onBackToHome }: IUploadingProps) {
  return (
    <div>
      {mode === "pending" ? (
        <div className="app-loading-wrapper">
          <div className="loading">
            <DotLottieReact
              data={UploadModelLoading}
              loop
              autoplay
              style={{ width: 300, height: 300 }}
            />
            <p>Building your quote...</p>
          </div>
        </div>
      ) : (
        <div className="loading">
          <img src={loadingFileFailIcon} alt="" />
          <p className="loading-fail-title">Upload failed</p>
          <Button
            customType={Type.NEUTRAL}
            customSize={Size.MEDIUM}
            hierarchy={ButtonHierarchy.TONAL}
            onClick={onBackToHome}
          >
            <img src={arrowBackIcon} style={{ verticalAlign: "middle" }}></img>
            Back to home
          </Button>
        </div>
      )}
    </div>
  )
}

export default Uploading
