import React from "react"
import { ConfigProvider, Input, InputProps, InputRef } from "antd"

interface TextFieldProps extends InputProps {}

const TextField = React.forwardRef<InputRef, TextFieldProps>((props, ref) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: props.size === "small" ? 2 : 4,
          colorBgContainerDisabled: "var(--bg-base-gray)",
          colorTextDisabled: "var(--text-gray-normal)",
          colorTextPlaceholder: "var(--text-gray-normal)"
        },
        components: {
          Input: {
            activeBorderColor: "var(--border-base-info)",
            hoverBorderColor: "var(--border-base-info)",
            activeShadow: "var(--drop-shadow-info-focus-2px-200)",
            errorActiveShadow: "var(--drop-shadow-danger-focus-2px-200)",
            paddingBlock: 10,
            paddingInline: 10,
            paddingBlockSM: 8,
            paddingInlineSM: 8,
            colorText: "var(--text-gray-stonger)",
            colorBorder: "var(--border-gray-strong)",
            colorBgContainer: "var(--bg-base-base)",
            colorTextPlaceholder: "var(--text-gray-normal)"
          }
        }
      }}
    >
      <Input {...props} ref={ref} className={props.className} />
    </ConfigProvider>
  )
})

TextField.displayName = "TextField"

export default TextField
